/* .footer-wrapper{
    background: #E11F1C;
}

footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    text-align: center;
}
.join-section{
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.join-section form{
    border: 1px solid white;
    overflow: hidden;
}

.join-section .custom-input{
    width: 600px;
    background: rgba(255, 255, 255, 0.2);
    font-weight: light;
    border-radius: 0;
    padding-left: 1rem;
    margin: 0 !important;
}
.footer-social{
    width: 250px;
    text-align: center;
}
.footer-social ul{
padding: 0;
list-style: none;
display: flex;
justify-content: space-between;
margin-bottom: 0;
}
.footer-social ul a{
    color: var(--white);
    font-size: 35px;
}
footer .last-section{
    text-align: center;
    margin-top: 1rem;
}
footer .last-section p{
    color: var(--white);
    font-size: 16px;
    margin-top: 1rem;
}
footer .last-section h2{
    color: var(--white);
}

footer button{
    border-radius: 0 !important;
    transform: translateY(-1px);
}

footer button:focus{
    outline: none !important;
} */

/* Footer --------------------------------------------------------------------- */
/* footer */

.custom-footer{
    background: url(../images/lp-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    color: var(--light-gray);
}


.footer-icons svg {
    font-size: 2rem;
}

.custom-footer .rounded-input-group {
    width: 100%;
}

.footer-desc{
    line-height: 1.3em;
    font-size: 0.9rem;
    letter-spacing: 1px;
}

.custom-footer ul{
    padding-left: 0;
}

.footer-fr{
    padding-inline: 1em;
}

.custom-footer li span{
    margin-left: 8px;
}

.footer-icons svg:hover{
    color: var(--primary);
}

.footer-heading{
    display: inline-block;
    box-shadow: inset 0 -1px rgb(255 255 255 / 10%);
}

.footer-desc{
    letter-spacing: 0px;
    font-size: 1rem;
}
/* Footer End --------------------------------------------------------------------- */