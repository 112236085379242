:root {
  --primary: #E11F1C;
  --secondary: rgb(74, 52, 11);
  --white: #fff;
  --black: #000000;
  --gray: gray;
  --dark-gray: #a6a6a6;
  --dark: #404040;
  --light: #F3F3F3;
  --lighter: #DADADA;
  --grey-bg: #F3F3F3;
  --highlight: #c4743f;
  --light-gray: #D3D3D3;
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

/* @import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap'); */
@font-face {
  font-family: "exposit";
  src: URL("../fonts/Exposit-Bold.otf") format("truetype");
}

@font-face {
  font-family: "deftone";
  src: URL("../fonts/deftone\ stylus.ttf") format("truetype");
}

@font-face {
  font-family: "Bebas Neue";
  src: URL("../fonts/Bebas_Neue_Pro_Book.otf") format("truetype");
}

@font-face {
  font-family: "josefin Sans";
  src: URL("../fonts/JosefinSans-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Mistral";
  src: URL("../fonts/mistral.ttf") format("truetype");
}

@font-face {
  font-family: "Breef serif";
  src: URL("../fonts/BreeSerif-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Courgette';
  src: url('../fonts/Courgette-Regular.ttf');
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/static/Raleway-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/static/Raleway-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/static/Raleway-ExtraBold.ttf');
  font-weight: 800;
}

.courgette {
  font-family: 'Courgette';
}

body {
  font-family: "Breef serif";
  overflow: overlay;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.7 !important;
}

input:focus-visible {
  outline: none;
}

input:focus::placeholder {
  color: transparent;
}

p {
  letter-spacing: 2px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: initial;
}

.btn {
  font-size: 17px;
  font-weight: 500;
}



ul {
  list-style-type: none;
}


.btn:focus {
  box-shadow: none;
}

.primary-btn {
  background: var(--primary);
  color: var(--white);
}

.primary-btn:hover {
  background: transparent;
  border: 1px solid var(--primary) !important;
  color: var(--primary);
}

.secondary-btn {
  background: var(--white);
  color: var(--primary);
}

.secondary-btn:hover {
  background: var(--secondary);
  color: var(--primary);
}

.banner-section {
  background-image: url("../images/banner-img.jpg");
  background-color: #000000;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  z-index: 0;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
}

.banner-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}


.absolute-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
}

.banner-section img {
  width: 100%;
}

.banner-text {
  /* position: absolute;
    bottom: 0; */
  color: var(--white);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* right: 0;
    left: 0; */
  font-size: 99px;
  font-family: "josefin Sans";
}

.common-heading {
  letter-spacing: normal;
  line-height: 1.2em;
  font-size: 32px;
  font-family: "josefin Sans";
}

.highlight {
  color: var(--primary) !important;
  font-weight: 600;
}

.underlined-text {
  text-decoration: underline;
}

.custom-card {
  width: 350px;
  margin: 1rem auto;
}

.custom-card img {
  width: 100%;
}

.custom-card h3 {
  color: var(--secondary);
  text-align: center;
  margin-top: 1rem;
}

.section {
  margin-top: 5rem;
}

.section2 {
  position: relative;
  padding-bottom: 5rem;
}

.normal-padding {
  padding-top: 5rem;
}

.section-background {
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  bottom: 0;
  height: 100%;
}

.custom-card-two {
  width: 357px;
  margin: 1rem auto;
}

.custom-card-two img {
  width: 100%;
}

.custom-card-two h3 {
  font-size: 20px;
  color: var(--white);
  text-align: center;
}

.secondary-text {
  color: var(--secondary);
}

.card-title {
  color: var(--primary);
  font-family: "Breef Serif";
  font-weight: 600;
}

.card-lg h4 {
  line-height: 1.2em;
  font-size: 32px;
  letter-spacing: 2px;
}

.card-lg p {
  line-height: 1.7em;
  font-size: 17px;
  letter-spacing: 2px;
  margin: 2.5rem 0;
  color: var(--secondary);
  text-align: justify;
}

.card-lg:nth-child(1) {
  width: 450px;
  margin: 3rem auto;
}

.card-image-two {
  position: absolute;
  width: 105%;
  left: -12px;
  height: 550px;
  top: 0;
  bottom: 0;
}

.custom-input {
  border-radius: 5px;
  background: var(--black);
  border: none;
  height: 40px;
  color: #fff;
}

::placeholder {
  color: var(--white);
  font-weight: 500;
}

.Background-fixed {
  background-image: url("../images/lastsection.jpeg");
  background-color: #000000;
  background-attachment: fixed;
  background-position: center;
}

.ImageBackgroundWrap {
  position: relative;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-text {
  padding: 13%;
  color: white;
  text-align: center;
}

.card-lg-smos-img {
  /* position: sticky;
    width: 105%;
    left: -12px;
    top: 0;
    bottom: 40px; */
  width: 100%;
}

.col-without-padding .col-lg-6 {
  padding: 0;
}

.first-section-with-title {
  padding-top: 4rem;
  text-align: center;
}

.heading-section-border {
  padding: 2rem 0;
  border: 3px solid var(--dark-gray);
  border-left: none;
  border-right: none;
}

.heading-section-border h2 {
  margin: 0;
  color: var(--primary);
}

.items {
  display: flex;
  margin-top: 1rem;
}

.items img {
  width: 250px;
}

.items .title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.items .title-section .first {
  width: 90%;
  display: flex;
  align-items: center;
  border-bottom: 5px dotted var(--primary);
  color: var(--primary);
}

.items .title-section .first svg {
  fill: var(--primary);
  margin-right: 0.5rem;
}

.items .title-section sub {
  font-size: 20px;
}

.items p {
  font-size: 20px;
  margin-top: 1rem;
}

.items .title-section h3 {
  margin: 0;
}

.image-section {
  overflow: hidden;
}

.shop-item a .image-section:hover>img {
  transform: scale(1.1);
}

.shop-item a {
  text-decoration: none;
  overflow: hidden !important;
  display: inline-block;
  color: var(--black);
}

.shop-item img {
  transition: all 1s;
}

.shop-item .shop-title {
  font-family: 300 !important;
  font-size: 25px;
}

.primary-text {
  color: var(--primary);
}

.branch-card {
  padding: 1rem 2rem;
  border-radius: 8px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 3px 5px -3px #a2a2a2;
}

.branch-card img {
  width: 80%;
  margin: auto;
}

.offices-section {
  height: 100vh;
  display: flex;
  align-items: center;
}

.branch-card .section-title {
  font-size: 24px;
}

.branch-card .address {
  text-align: justify;
  text-align-last: center;
}

.branch-card .section-title::first-letter {
  color: var(--primary);
}

/* .branch-card p {
  font-size: 22px;
  color: var(--secondary);
  margin: 1rem 0;
} */

/* =========================.investment-section========================= */




.invest-options-modal .modal-content {
  border-radius: 0;
  background: var(--light);
  color: var(--dark);
}

.invest-options-modal .modal-content button {
  color: var(--dark);
  font-size: 14px;
}

.invest-options-modal .modal-content button:hover {
  color: var(--light);
}


#investements {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#investements::before {
  background-image: url("../images/coffe-banner.jpg");
  background-size: cover;
  background-position: center;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.9;
}

.investment-section {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.investment-section .banner-text {
  font-family: inherit;
}

.investment-section h4 {
  font-size: 24px;
  color: var(--white);
}

.investment-section h2 {
  font-size: 70px;

  color: var(--primary);
  font-family: "Breef Serif";
}

.investment-section p {
  text-align: right;
  font-size: 26px;
  color: var(--secondary);
}

.invest-second {
  padding: 2rem 0;
  position: relative;
  background: #fafafa96;
}

.invest-bg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  height: 800px;
  z-index: -1;
  opacity: 0.3;
}

.primary {
  color: var(--primary);
}

.investment-second {
  margin-top: 50px;
}

.investment-second h3 {
  font-size: 34px;
  color: var(--secondary);
}

.investment-second h2 {
  font-size: 70px;
  color: var(--primary);
}

.investment-second p {
  font-size: 18px;
  color: var(--secondary);
}

.invest-second .invest-search-input {
  flex-basis: 100%;
}

.store-select-wrapper {
  width: 200px !important;
}

.stores-list {
  box-shadow: none !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #fff !important;
  padding-right: 0.5rem !important;
}

.store-select .MuiPaper-root::-webkit-scrollbar {
  width: 6px !important;
}


.invest-second .invest-search-input input {
  padding: 14px;
  margin-right: 24px;
  width: 590px;
  color: var(--secondary);
  border-radius: 4px;
  border-color: var(--primary);
}

.invest-second .invest-search-input input:focus {
  border-color: var(--secondary);
  outline: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: var(--primary);
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: var(--primary);
}

::placeholder {
  color: var(--primary);
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
  border-width: 2px;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--primary) !important;
}

.MuiMenu-list {
  background-color: var(--light);
  color: var(--dark);
}

.MuiMenu-paper::-webkit-scrollbar {
  width: 4px;
  background: var(--light);
}

.MuiMenu-paper::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray);
}

.MuiPaper-root {
  height: auto;
}

.invest-second .btn {
  padding: 14px 20px;
  margin-left: 5px;
}

.invest-second .bg-image {
  background-image: url("../images/Textura.webp");
  background-size: cover;
  border-radius: 10px;
}

.symbol-text {
  font-size: 14px;
  color: var(--secondary);
}

#investment-simulation .bg-image .content h3 {
  margin: 0px;
  font-size: 24px;
  padding: 10px 0px;
}

#investment-simulation .image img {
  width: 100%;
}

#investment-simulation .content p {
  font-size: 22px;
}

.table-images-txt {
  padding: 10% 0px !important;
}

.section2 .img img {
  width: 60%;
}

.section-documentation .section2 {
  padding: 0;
}

.section-documentation {
  color: var(--secondary);
}

.section-documentation h3,
.section-documentation h4 {
  color: var(--primary);
}

#section-bg-atteched .background-text .images img {
  width: 100%;
}

/* ===================invest-now-card============================= */



.invest-now-card {
  margin: 0.5rem;
  border-radius: 10px;
  padding: 0.5rem;
}

.invest-now-card .image-section figure {
  position: relative;
  margin: 0 auto;
}

.invest-now-card .image-section figure img {
  transition: all 1s;
  /*width: 100%;*/
}

.invest-now-card .image-section figure figcaption img {
  width: 25px;
}

.invest-now-card .image-section figure figcaption {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  padding: 10px;
}

.invest-now-card .image-section figure figcaption span {
  /* margin-left: 10px; */
  line-height: 1em;
  padding: 0;
}

.invest-now-card .image-section:hover .invest-now-card .image-section figure img {
  transform: scale(1.1);
}

.invest-now-card .line {
  width: 100%;
  height: 10px;
  background: #ff4141;
  border-radius: 12px;
  margin-bottom: 15px;
}

.invest-now-card h4 {
  font-size: 1.3rem;
  margin: 10px 0px 0px 5px;
  color: var(--dark);
}

.invest-now-card .invest-card-desc {
  color: var(--dark);
  margin-bottom: 5px;
  font-size: 0.9rem;
  line-height: 1.3em;
  padding: 0.3em 1em 0 5px;
  text-align: start;
  letter-spacing: 0;
}

.invest-card-desc.less {
  height: 2.6em;
  overflow: hidden;
}

.show-more {
  display: inline-block;
}

.show-more p {
  margin-bottom: 0;
  font-size: 0.8rem;
  margin-left: 5px;
  letter-spacing: 0;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 9.5px 14px;
} */
.state-ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.state-ul li {
  padding: 0.3rem 0;
}

.state-ul li a {
  color: var(--secondary);
  text-decoration: none;
  font-size: 20px;
}

.state-ul li a:hover {
  color: var(--secondary);
}

.race-item a {
  color: var(--primary);
  font-size: 22px;
}

.race-item p {
  font-size: 20px;
}

.team-img {
  width: 100%;
}

.form-heading {
  color: var(--primary);
}

.card-icons {
  display: flex;
  gap: 1.2em;
}

.t-card-img {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-card-desc {
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.t-card-wrapper {
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: var(--white);
  padding: 1em;
  box-shadow: 1px 1px 1px 1px var(--light-gray);
}

.team-section .section-title {
  margin-bottom: 1rem;
}


.contact-right {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-item {
  display: flex;
  align-items: start;
  margin-bottom: 0.5em;
}

.info-item-body {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.info-title {
  font-weight: 500;
  padding-left: 10px;
  margin-bottom: 0;
}

.info-desc {
  padding-left: 10px;
  font-size: 14px;
  width: 70%;
  margin-left: auto;
  letter-spacing: 0;
}

.info-desc:not(:first-child) {
  word-wrap: break-word;
}

.social-icons {
  width: 30%;
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
}

.social-icons i {
  font-size: 1.2rem;
  color: var(--dark);
  opacity: 0.7;
}

.contact-map {
  width: 100%;
}

.contact-form {
  padding: 1em;
}


.contact-form input,
.contact-form textarea {
  margin-bottom: 1rem;
  border: 1px solid var(--light);
  background-color: var(--light);
  padding: 0.5em 1em;
}

.contact-form input:hover,
.contact-form textarea:hover {
  border: 1px solid var(--primary);
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none !important;
  box-shadow: none;
  border-color: var(--primary);
  background-color: var(--light);
}

.contact-image {
  width: 400px;
  text-align: center;
}

.last-section h2 {
  font-family: "josefin Sans";
}

.blog-creater {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-creater .inner {
  display: flex;
  align-items: center;
}

.blog-creater img {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin-right: 0.5rem;
}

.blog-creater span {
  font-size: 14px;
}

.blog-body h3 {
  font-size: 40px;
  font-weight: 600;
  font-family: arial;
  margin-top: 1rem;
}

.blog-body p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-footer div span:not(:first-child) {
  margin-left: 2rem;
}

.blog-footer i {
  color: var(--primary);
}

.blog-img {
  width: 100%;
}

.custom-card .tag {
  fill: var(--primary);
  width: 90px;
  position: absolute;
  top: -26px;
  right: -22px;
}

.custom-card .tag div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-card .tag div span {
  position: absolute;
  font-size: 25px;
  color: var(--white);
  font-family: "josefin Sans";
}

/* ============================dashboard-style==================== */
.css-o5srbh-MuiPaper-root-MuiAppBar-root {
  background-color: #ff4141 !important;
}

.dashboard-logo {
  margin-top: 8px;
  width: 100px !important;
}

/* ---------------------dashboard-design----------------------- */
.css-1o1hm4z-MuiDrawer-docked .MuiDrawer-paper {
  z-index: 222222;
}

.dashboard-box {
  /* width: 260px; */
  /* height: 185px; */
  border-radius: 15px !important;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important; */
  padding: 30px 30px 0px !important;
  background-color: #F3F3F3 !important;
  border: 1px solid var(--light) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.dashboard-box:hover {
  border-color: var(--primary) !important;
}

.dashboard-box h3 {
  font-size: 20px;
}

.dashboard-box h4 {
  font-weight: 600;
  font-size: 35px;
  line-height: 52px;
  color: #6f6f6f;
}

.dashboard-box img {
  width: 30px;
}

.chevron-icon {
  display: none !important;
}

/* ========================404================= */
.not-found-image {
  position: relative;
}

.not-found-image img {
  width: 100%;
}

.not-found-image .button {
  position: absolute;
  bottom: 0;
}

.not-found-image .button a {
  color: var(--primary);
  text-decoration: none;
}


#custom::-webkit-input-placeholder {
  color: #FFF !important;
}

.css-1j9kmqg-MuiDataGrid-toolbarContainer {
  gap: 1em;
}

/* .MuiDataGrid-cell[data-field='actions'] svg:nth-child(2){
  color: red !important;
} */

.search-icon svg {
  color: var(--light);

}



/* new landing page css */

.recent-investors {
  margin-top: 1em;
  margin-bottom: 1em;
}

.main-header {
  height: 72px;
  background: rgba(228, 228, 228, 0.15) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 78px;
  right: 6px;
  z-index: 1;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 1rem;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .1);
}


.main-header-link {
  margin-left: 1em;
  color: var(--light);
}

.main-header-link:hover {
  color: var(--primary);
}

.header-line {
  width: 1px;
  height: 45px;
  background-color: var(--lighter);
  margin-left: 1em;
  margin-right: 1em;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left .search-icon {
  background: rgba(228, 228, 228, 0.15);
  border-radius: 6px;
  padding: 0em 10px;
  cursor: pointer;
}


.main-logo {
  width: 98px;
  height: 36px;
}

.main-logo img {
  width: 100%;
  height: 100%;
}

.section-logo {
  display: flex;
  justify-content: center;
  padding: 1em 0;
}

.search-box {
  display: flex;
  align-items: center;
}

.stat-box {
  border-radius: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  background: rgba(39, 39, 39, 0.8);
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.4);
  justify-content: center;
  position: absolute;
}

.stat-box.order-1 {
  top: 10%;
  left: 0;
  transform: translateX(-66%);
}

.stat-box.order-2 {
  top: 10%;
  right: 0;
  transform: translateX(71%);
}

.stat-box.order-3 {
  top: 37%;
  left: 0;
  transform: translateX(-59%);
}

.stat-box.order-4 {
  top: 66%;
  left: 0;
  transform: translateX(-69%);
}

.stat-box.order-5 {
  top: 58%;
  right: 0;
  transform: translateX(65%);
}

.stat-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stat-number {
  margin-bottom: 0;
  padding-bottom: 0 !important;
  font-weight: 700;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: var(--light);
  line-height: 1em;
  padding-right: 9px;
}

.stat-icon {
  font-size: 1.8rem;
  margin-bottom: 0.3em;
  color: var(--light);
  width: 40px;
  margin-left: 10px;
}

.stat-icon img {
  width: 100%;
}

.stat-label {
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 16px;
  color: var(--light);
  margin-bottom: 0;
  line-height: 1.1em;
  margin-top: 0.5em;
  max-width: 110px;
}

.get-started-section .section-title {
  margin-bottom: 10px;
  font-weight: bold;
}

.get-started-body {
  width: 80%;
}


.get-started-section small {
  margin-top: 10px;
}

.get-started-img {
  width: 100%;
  aspect-ratio: 6/5;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.get-started-img img {
  width: 100%;
  min-height: 100%;
}


.landing-page {
  font-family: 'Bebas neue';

}

.lp-invest-i-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lp-invest-img {
  margin-bottom: 10px;
}

.normal-p,
.normal-p * {
  font-size: 1rem;
  color: #404040;
}

.normal-p.light-p {
  font-weight: 300;
  font-size: 20px;
  color: var(--white);
  line-height: 1.4em;
  letter-spacing: 1px;
}


.dark-text {
  color: #404040;
}

.red-text {
  color: #E11F1C;
}

.primary {
  color: var(--primary);
}

.failure {
  color: #ff6961;
}

.success {
  color: #4BB543;
}

.section-title {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 3rem;
  letter-spacing: 0;
  line-height: 1.2em;
  font-family: 'Raleway', sans-serif;
  color: var(--dark);
}

.section-title.light-title {
  font-weight: 500;
  font-size: 30px;
  color: var(--white);
}

.section-title.main-section-title {
  font-size: 50px;
}

/* 
.section-title::first-letter {
  color: var(--primary);
} */

.dashboard-title {
  font-family: 'Poppins', sans-serif;
  color: var(--dark);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1em;
  margin-bottom: 0;
}


.custom-section {
  padding: 5rem 0;
}

.clip-section {
  position: relative;
}

.clip-section .triangle {
  position: absolute;
  background-color: var(--light);
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  clip-path: polygon(100% 1%, 0% 100%, 100% 100%);
}

.ceo-message-box {
  border-left: 4px solid var(--primary);
  padding: 3em;
  background: white;
  box-shadow: inset -1px -1px 0px 0px var(--light-gray);
  border-radius: 10px;
}

.ceo-message-box .quote-icon {
  font-size: 3rem;
}

.ceo-message-box p {
  font-style: italic;
}


.large-p {
  font-size: 50px;
  font-weight: 600;
}

.grey-bg {
  background-color: #F3F3F3;
}

.icon {
  width: 20px;
}

.small-shade {
  box-shadow: 1px 1px 4px -2px gray;
}

.accordion-item {
  font-family: 'Raleway';
  box-shadow: 1px 1px 1px 0px var(--light-gray);
}

.main-text {
  text-align: justify;
  text-align-last: center;
  width: 50%;
  margin: auto;
}

.accordion-button {
  background-color: #F3F3F3 !important;
  font-weight: 600;
  color: #404040;
  font-size: 18px;
}

.accordion-button:focus {
  box-shadow: none;
  color: #404040;
}

.accordion-body {
  border: 7px solid #f3f3f3;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  content: '\2212';
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  background-image: unset;
}

.accordion-button:not(.collapsed) {
  color: #404040;
}


.accordion-button.collapsed::after {
  content: '\002b';
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  background-image: unset;
}

.accordion-item {
  margin-bottom: 1rem;
  border: none;
  border-radius: 8px;
  overflow: hidden;
}

.accordion-body {
  font-size: 20px;
  color: #404040;
}

.accordion-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accordion-section {
  padding: 3rem 0 5rem;
}

.target-img {
  display: flex;
}

.target-img img {
  width: 80%;
}

.main-section-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-top: 50%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.main-section {
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url(../images/lp-bg.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom center;
  transition: all 0.5s ease;
  /* border-bottom: 1px solid var(--light-gray); */
  box-shadow: inset 0 -1px rgba(0, 0, 0, .1);
  background-size: cover;
  background-position: center;
}

.header-section {
  background: url(../images/lp-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}


.text-section p {
  margin-bottom: 0;
}

.text-section .section-title {
  font-size: 40px;
  margin-bottom: 2rem;
}

.courgette {
  font-family: 'Courgette';
}

.lp-invest-i-wrapper {
  text-align: center;
}

.grid-section p,
.grid-section2 p:not(.large-p) {
  padding: 0 2rem 2rem;
}


.grid-section2 .large-p {
  margin-bottom: 0;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 0;
}

.red-bg {
  background-color: var(--primary);
}

.head-box {
  background-color: var(--primary);
  width: 100%;
  height: 200px;
  position: relative;
}

.head-box-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100px;
}

.main-section-text {
  padding-right: 78px;
}


.main-section .row p:last-child {
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
}

.rounded-input-group {
  background: rgba(228, 228, 228, 0.15);
  border-radius: 30px;
  width: 75%;
  height: 40px;
}

.rounded-input {
  background: rgba(228, 228, 228, 0);
  border-radius: 30px;
  border: none;
  padding: 0.2em 1em;
  color: var(--light-gray);
}

.rounded-input::placeholder {
  color: var(--light);
}

.main-section .normal-p *,
.main-section .normal-p {
  color: var(--light);
}

.main-section .section-title {
  color: var(--light) !important;
}

.main-section .row p:first-child {
  padding-bottom: 0.5rem;
}

.lp-shop-crop {
  width: 100%;
  height: 100%;
}

.lp-phone-img-wrapper {
  aspect-ratio: 5/11;
  width: 50%;
  position: relative;
}



.language-btn {
  border-radius: 30px;
  margin-right: 10px;
}

.language-btn button {
  border: none;
  margin: 1px;
  padding: 0 1em;
}

.language-btn button:first-child {
  border-radius: 30px 0 0 30px;
}

.language-btn button:last-child {
  border-radius: 0 30px 30px 0;
}

.language-btn .active-lng {
  background-color: var(--primary);
  color: #fff;
}

.button-group {
  display: flex;
  align-items: center;
}

.lp-btn {
  background-color: var(--primary);
  border-radius: 30px;
  padding: 0.2em 1.2em;
  color: var(--white);
  cursor: pointer;
  border: 1px solid var(--primary);
  text-decoration: none;
  min-width: 80px;
}

.lp-btn:hover {
  color: inherit;
}

.lp-btn.inverted-btn {
  background-color: var(--dark);
  border: 1px solid var(--dark);
  color: var(--light);
}

.lp-btn.full-btn {
  border-radius: 10px;
  width: 100%;
}

.dark-canvas .lp-btn:hover {
  color: var(--white);
  border-color: var(--white);
}

.center-wrapper {
  display: flex;
  justify-content: center;
}

.profile-card {
  background-color: var(--light) !important;
}

.simple-btn {
  border: none;
  border-radius: 30px;
  background-color: inherit;
}

.simple-btn.login {
  margin-right: 16px;
  color: var(--light);
}

.main-header .lp-btn:hover {
  color: var(--light);
}

.form-control:focus {
  color: var(--dark);
  background-color: #fff;
  border-color: var(--dark);
  outline: 0;
  box-shadow: none;
}

.simple-square-btn {
  border: 1px solid;
  padding: 0 5px !important;
  border-radius: 0;
  margin: 5px 5px 5px 5px;
  background: inherit;
  color: var(--primary);
}

.simple-square-btn:hover {
  color: var(--dark);
}

.red {
  color: var(--primary);
}


.lp-btn:hover {
  background-color: inherit;
  color: var(--black);
}


body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #E11F1C;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #E11F1C;
  border-radius: 10px;
}

.open-sidebar .main-section {
  left: 172px !important;
}

.scroll-icon-wrapper {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.scroll-icon-wrapper img {
  width: 100%;
  height: 100%;
}

.pure-section-text {
  width: 75%;
  margin: auto;
  text-align: center;
}


.custom-section.pure-section {
  padding: 4rem;
}

.pure-section .section-title {
  padding-bottom: 0rem;
  margin-bottom: 1rem;
}

/* investment page changes */
.user-invest-main {
  padding: 5px 0 !important;
}

.investment-page .section-title {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 28px;
}

.investment-section .section-title {
  color: var(--white);
  font-size: 3rem;
  width: 80%;
  text-align: center !important;
  margin-bottom: 1rem !important;
  text-shadow: 1px 1px grey;
}

.investment-section .normal-p {
  color: var(--white);
  text-align: center;
  width: 80%;
  text-shadow: 1px 1px grey;
}

.investment-page .pure-section-text {
  text-align: justify;
  text-align-last: center;
}



.pure-card h3 {
  padding-bottom: 2rem;
}

.pure-card p {
  text-align: justify;
}

.light-bg {
  background-color: var(--light) !important;
}



.tree-bg {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(12, 12, 12, 0.5)),
    url(./../images/banner-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}



/* fazal css  */
.dashboard-main {
  /* display: flex; */
  /* margin-left: 78px; */
  display: flex;
  overflow: hidden;
}

.sidebar {
  z-index: 999;
  left: 0 !important;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden !important;
  /* position: unset; */
}

.open-admin-sidebar {
  left: 0;
}

.dashboard-section-main {
  margin-left: 78px;
  display: block !important;
  width: 100%;
  background: var(--light);
}


.dashboard-section-main .MuiGrid-item:not(.db-chart) {
   margin: 0.7em;
  padding: 0;
}



.custom-box {
  background-color: #fff;
  box-shadow: 0 3px 5px -3px #a2a2a2;
  border-radius: 5px;
  padding: 0.7em;
}

.custom-box.cutting-box {
  width: 80%;
  padding: 2em;
  width: 100%;
  transform: translateY(-100px);
}

.light-img-bg {
  background: url('./../images/light-bg.jpg') !important;
}

.custom-box.login {
  padding: 4em 1em;
  border-radius: 0.6em;
}

.dashboard-section-main .MuiGrid-container:not(.db-container) {
  background-color: #fff;
  box-shadow: 0 3px 5px -3px #a2a2a2;
  border-radius: 5px;
  /* padding: 1rem; */
}

.dashboard-section-main header {
  background-color: transparent;
  box-shadow: none;
}

.db-container {
  justify-content: center;
}

/* body{
  overflow: hidden;
} */
.modal-li-flex {
  display: flex;
  align-items: center;
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard {
  border: none !important;
}

.MuiDataGrid-row.even {
  background-color: var(--light);
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  margin-bottom: 0;
}

.MuiDataGrid-row .MuiGrid-container {
  background-color: inherit;
  box-shadow: none;
}

.MuiDataGrid-columnHeaderDraggableContainer {
  background-color: white !important;
}

.MuiDataGrid-cell {}

.MuiDataGrid-cell:nth-child(even) {}

.flex-with-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a6a6a6;
}

.landing-main {
  display: flex;
}

.landing-main-page {
  height: 100vh !important;
  overflow-y: scroll;
  width: 100%;

}


/* login form */



/* Investor Slider----------------------------------------------------------------------- */
.rounded-square-img {
  width: 100%;
  aspect-ratio: 5/5;
  display: flex;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
}

.rounded-square-img img {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.person-item {
  padding: 0 1em;
}

.person-item-desc {
  font-size: 0.8rem;
  margin-bottom: 5px;
  letter-spacing: 1px;
  color: var(--dark);
}

.person-item-days {
  font-size: 0.7rem;
  color: var(--dark-gray);
}

.person-item-wrapper {
  border-right: 1px solid var(--light-gray);
}

/* Investor Slider End----------------------------------------------------------------------- */

/* Open section -------------------------------------------------------------------- */

.pitch-card-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 6/7;
  border-radius: 5px;
  margin-top: 4rem;
}

.pitch-card {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
  background: url('../images/lp-bg.png');
  border-radius: 5px;
}

.pitch-card-item {
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.15);
  margin-bottom: 1em;
  border-radius: 5px;
}

.flex-p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  font-size: 0.9rem;
  color: inherit;
}

.white {
  color: var(--white);
}

.flex-p span:first-child {
  font-weight: 500;
  letter-spacing: 1px;
  opacity: 0.8;
}

.muted-text{
  opacity: 0.8;
}

.white-muted{
  color: var(--white);
  opacity: 0.8;
}

.pitch-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 1em;
  border-radius: 5px;
  backdrop-filter: brightness(0.7);
  border-radius: 5px;
}

.pitch-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  padding: 1em;
  border-radius: 5px;
  backdrop-filter: brightness(0.7);
  border-radius: 5px;
}

.pitch-card:hover {
  transform: rotateY(180deg);
}

.open-header {
  padding-right: 1em;
  padding-top: 2rem !important;

}

.open-header ul:not(.open-header-sub-menu)>li {
  box-shadow: inset 0 -1px rgba(0, 0, 0, .1);
}


.open-header ul {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  flex-direction: column;
}



.open-pitch ul {
  display: block;
}

.open-pitch {
  padding-right: 1em;
}

.open-section {
  min-height: 100vh;
}

.open-section dt {
  font-size: 1.3rem;
  color: var(--dark);
  margin-bottom: 0.5em;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .1);
}

.open-section .section-title {
  margin: 2rem 0 0.7em;
  text-align: start;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
}

.open-header li {
  /* padding-bottom: 50px; */
  margin-bottom: 1em;
  font-weight: 700;
  color: var(--dark-gray);
  cursor: pointer;
}

.open-header-sub-menu {
  margin-left: 0.5em;
  box-shadow: inset 1px 0 rgba(0, 0, 0, .1);
  transition: all 1s ease;
  margin-bottom: 1em !important;
}

.open-header-sub-menu li {
  padding-bottom: 0;
  padding-left: 0.5em;
}

.open-header-sub-menu li:last-child {
  margin-bottom: 0 !important;
}

.open-header-sub-menu li.active {
  color: var(--primary);
  border-bottom: none !important;

}

.open-header li.active {
  border-bottom: 3px solid var(--primary);
  color: var(--primary);
}

.open-header li:hover {
  color: var(--primary);
}

.deal-term-wrapper {
  background: var(--grey-bg);
  padding: 1em;
  border-radius: 5px;
  border: 1px solid var(--light-gray);
}

.deal-term {
  box-shadow: inset 0 -1px rgba(0, 0, 0, .08);
  padding-bottom: 1em;
  margin-bottom: 1em;
  color: var(--dark);
}

.deal-term p {
  margin: 0;
  letter-spacing: 1px;
}

.deal-term-header {
  display: flex;
  justify-content: space-between;
}

.deal-term-title,
.deal-term-desc {
  font-size: 0.9rem;
}

.deal-term-stat {
  font-size: 1.2rem;
  font-weight: 600;
}

.deal-term-body {
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.deal-term:hover .deal-term-body {
  max-height: 1000px;
}

/* @keyframes slideup {
  0%{
    max-height: 0;
  }
  100%{
    max-height: 1000px;
  }
} */
.deal-term:hover .icon svg {
  color: var(--primary);
}

.section-img {
  aspect-ratio: 5/3;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 1em;
}

.section-img img {
  width: 100%;
  min-height: 100%;
}

.open-pitch-video {
  width: 100%;
  aspect-ratio: 5/4;
  border-radius: 10px;
  overflow: hidden;
}

/* Open section End-------------------------------------------------------------------- */


/* Blog section of landing page */

.blog-card {
  background-color: var(--white);
  border-radius: 0.3rem;
  overflow: hidden;
  box-shadow: 1px 1px 1px 1px var(--light-gray);

  height: 500px;
  position: relative;
}


.blog-card-footer p {
  margin-bottom: 0;
}

.footer-img {
  width: 100%;
}

.footer-img img {
  width: 100%;
  border-radius: 5px;
  opacity: 0.9;
}

.blog-card-img img {
  width: 100%;
}

.blog-card-title {
  font-size: 1.3rem;
  color: var(--dark);
  margin-bottom: 0.5em;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.blog-card-body-wrapper {
  padding: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--white);
  width: 100%;
}

.t-card-item .card-title {
  font-family: 'Poppins';
  color: var(--dark);
}

.icon-img {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
}

.icon-img img {
  width: 100%;
  height: 100%;
}

.blog-card-footer {
  display: flex;
  gap: 1em;
}

.card-person {
  font-weight: 600;
}

.card-time {
  font-size: 0.7rem;
}

.card-desc {
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.blog-section .slick-arrow::before {
  color: var(--primary);
  font-size: 1.5rem;
}

.team-section .slick-arrow::before {
  color: var(--primary);
  font-size: 1.5rem;
}

.testimonial-card {
  background: var(--white);
  padding: 1em;
  border-radius: 0.3rem;
  box-shadow: 1px 1px 1px 0px var(--light-gray);
  position: relative;
}

.testimonial-card .quote-icon {
  position: absolute;
  font-size: 3rem;
  color: var(--dark);
}

.testimonial-card .quote-icon:first-child {
  top: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  transform: translateY(-50%);
}

.testimonial-card .quote-icon:nth-child(2) {
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  transform: translateY(50%);
}

.testimonial-card .row {
  align-items: center;
}

.testimonial-card .image-wrapper {
  aspect-ratio: 1/1;
  border-radius: 50%;
  width: 70%;
  overflow: hidden;
  margin: auto;
}

.image-wrapper {
  display: flex;
  align-items: center;
}

.image-wrapper img {
  width: 100%;
  min-height: 100%;
}

.custom-card-title {
  margin-block: 0.5rem;
  text-align: start;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
}

.card-line {
  width: 10%;
  height: 5px;
  background: var(--primary);
}

.rating-stars {
  display: flex;
  gap: 0.5em;
}

.rating-stars svg {
  font-size: 1.5rem;
  color: #ce9f56;
}

.testimonial-section .slick-arrow::before {
  color: var(--primary);
  font-size: 1.5rem;
}

/* Blog page */
.blog-title {
  text-align: start;
  font-size: 24px;
  font-weight: 700;
  color: var(--dark);
  position: relative;
}

.blog-desc {
  padding-top: 10px;
}

.blog-title::after {
  position: absolute;
  content: '';
  width: 5%;
  height: 5px;
  background-color: var(--primary);
  left: 0;
  bottom: -10px;
}

.blog-main-img {
  overflow: hidden;
  display: flex;
  aspect-ratio: 5/2;
  align-items: center;
}

.blog-main-img img {
  width: 100%;
  min-height: 100%;
}

.blog-desc,
.blog-page dd {
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.blog-page dt {
  font-size: 1.3rem;
  color: var(--dark);
  margin-block: 0.5em;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .1);
  display: inline-block;
}

.blog-page ul {
  list-style-type: disc;
  line-height: 2em;
}

.blog-page .blog-card {
  box-shadow: none;
  border: 1px solid var(--light-gray);
}

.blog-main {
  padding: 1em;
}

/* notice section */

